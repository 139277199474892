import React from 'react'
import { graphql } from 'gatsby'

import { getSiteData } from '@/utils/siteData'
import Layout from '@/components/layout'
import Sections from '@/components/sections'
import SEO from '@/components/seo'

const PartnerPage = ({ data, pageContext }) => {
  const { site, downloads, strapiContent } = data
  const { sections, title } = strapiContent || {}
  const global = {
    downloads: downloads ? downloads.nodes : [],
  }

  const seo = {
    metaTitle: title,
    metaDescription: `Unser Partner ${title}`,
  }

  // const heroProps = {
  //   strapi_component: 'sections.hero-compact',
  //   image: cover,
  //   space: 'none',
  //   disableTopMargin: true,
  // }

  const finalSections = sections

  return (
    <>
      <SEO
        title={title}
        seo={seo}
        site={site.nodes[0]}
        pageContext={pageContext}
      />
      <Layout global={getSiteData(data)} pageContext={pageContext}>
        <Sections as="main" sections={finalSections} global={global} />
      </Layout>
    </>
  )
}

export default PartnerPage

export const query = graphql`
  query PartnerPageQuery($id: String!, $locale: String!) {
    locales: allLocale(filter: { language: { eq: $locale } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    site: allStrapiSite(filter: { locale: { eq: $locale } }) {
      nodes {
        ...Site
      }
    }
    header: allStrapiHeader(filter: { locale: { eq: $locale } }) {
      nodes {
        ...Header
      }
    }
    footer: allStrapiFooter(filter: { locale: { eq: $locale } }) {
      nodes {
        ...Footer
      }
    }
    strapiContent: strapiPartner(id: { eq: $id }) {
      slug
      title
      sections {
        ... on STRAPI__COMPONENT_SECTIONS_ANCHOR_NAVIGATION {
          ...SectionsAnchorNavigation
        }
        ... on STRAPI__COMPONENT_SECTIONS_BADGE_DIVIDER {
          ...SectionsBadgeDivider
        }
        ... on STRAPI__COMPONENT_SECTIONS_COLUMNS {
          ...SectionsColumns
        }
        ... on STRAPI__COMPONENT_SECTIONS_COLUMNS_HEADING_IMAGE {
          ...SectionsColumnsHeadingImage
        }
        ... on STRAPI__COMPONENT_SECTIONS_COLUMNS_HEADING_TEXT {
          ...SectionsColumnsHeadingText
        }
        ... on STRAPI__COMPONENT_SECTIONS_COLUMNS_HEADING_VIDEO {
          ...SectionsColumnsHeadingVideo
        }
        ... on STRAPI__COMPONENT_SECTIONS_COLUMNS_HIGHLIGHT_IMAGE {
          ...SectionsColumnsHighlightImage
        }
        ... on STRAPI__COMPONENT_SECTIONS_COLUMNS_HIGHLIGHT_TEXT {
          ...SectionsColumnsHighlightText
        }
        ... on STRAPI__COMPONENT_SECTIONS_COLUMNS_HIGHLIGHT_VIDEO {
          ...SectionsColumnsHighlightVideo
        }
        ... on STRAPI__COMPONENT_SECTIONS_FEATURE_LIST {
          ...SectionsFeatureList
        }
        ... on STRAPI__COMPONENT_SECTIONS_FEATURE_DESCRIPTION {
          ...SectionsFeatureDescription
        }
        ... on STRAPI__COMPONENT_SECTIONS_HEADING_IMAGE {
          ...SectionsHeadingImage
        }
        ... on STRAPI__COMPONENT_SECTIONS_HEADING_SECTION {
          ...SectionsHeadingSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_HERO_COLLECTION {
          ...SectionsHeroCollection
        }
        ... on STRAPI__COMPONENT_SECTIONS_HERO_SECTION {
          ...SectionsHeroSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_HIGHLIGHT_FIGURE {
          ...SectionsHighlightFigure
        }
        ... on STRAPI__COMPONENT_SECTIONS_HIGHLIGHT_LOGO {
          ...SectionsHighlightLogo
        }
        ... on STRAPI__COMPONENT_SECTIONS_HIGHLIGHT_PLATFORM {
          ...SectionsHighlightPlatform
        }
        ... on STRAPI__COMPONENT_SECTIONS_HIGHLIGHT_STATS {
          ...SectionsHighlightStats
        }
        ... on STRAPI__COMPONENT_SECTIONS_HIGHLIGHT_TEXT {
          ...SectionsHighlightText
        }
        ... on STRAPI__COMPONENT_SECTIONS_PARTNER_CLOUD {
          ...SectionsPartnerCloud
        }
        ... on STRAPI__COMPONENT_SECTIONS_PARTNER_COLLECTION {
          ...SectionsPartnerCollection
        }
        ... on STRAPI__COMPONENT_SECTIONS_TEASER_COLLECTION {
          ...SectionsTeaserCollection
        }
        ... on STRAPI__COMPONENT_SECTIONS_TEASER_IMAGE {
          ...SectionsTeaserImage
        }
        ... on STRAPI__COMPONENT_SECTIONS_TEASER_LOGO {
          ...SectionsTeaserLogo
        }
        ... on STRAPI__COMPONENT_SECTIONS_TESTIMONIAL_COLLECTION {
          ...SectionsTestimonialCollection
        }
        ... on STRAPI__COMPONENT_SECTIONS_TESTIMONIAL_SECTION {
          ...SectionsTestimonialSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_TEXT_SECTION {
          ...SectionsTextSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_TOPIC_COLLECTION {
          ...SectionsTopicCollection
        }
        ... on STRAPI__COMPONENT_SECTIONS_DOWNLOADS {
          ...SectionsDownloads
        }
      }
    }
    downloads: allStrapiDownload(filter: { locale: { eq: $locale } }) {
      nodes {
        ...Download
      }
    }
  }
`
